import React from "react"
import "./cssberhasil"
import Navbar from "./NavbarSub"
import Header from "./HeaderSub"
import Footer from "./Footer"
import Banner from "../content/ExperienceCT/Banner"
import Content from "../content/ExperienceCT/Content"
import HeaderImg from "../content/ExperienceCT/HeaderImage"
import ImgEx from "../content/ExperienceCT/ImageEx"

import ImageEx from "../content/ExperienceBA/ServicesCont"
import TitleContent from "../content/ExperienceBA/TitleContent"
import DiagramCT from "../content/ExperienceCT/DiagramCT"
// import AccordionCT from "../content/ExperienceCT/AccordionCT"

import HeadTitle from "../content/ExperienceCT/HeadContent"
import ContactBus from "../content/ContactBus"
import Mobile from "../content/Mobile"
import SEO from "../SEO"

import Helmet from "react-helmet"
import JSberhasil from "./jsberhasil"

const LayoutExperienceCT = () => {
  return (
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class:
            "home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074",
        }}
      />
      <SEO
        title="Pharmetrics Lab | Clinical Trial CRO Experiences "
        description="Pharmametrics Lab as a leading CRO company in Indonesia have conducted clinical trials ranging from oncology to traditional medicine for major companies in ASEAN"
      />
      {/* <div id="loftloader-wrapper" className="pl-imgloading" data-show-close-time="15000">
                <div className="loader-inner">
                    <div id="loader">
                        <div className="imgloading-container">
                            <span style= {{backgroundImage: `url(${LoadingLogo})`}} ></span>
                        </div>
                        <img alt="loader image" src={LoadingLogo} />
                    </div>
                </div>
                <div className="loader-section section-fade">
                </div>
                <div className="loader-close-button">
                    <span className="screen-reader-text">Close</span>
                </div>
            </div> */}

      <div id="perspective">
        <Mobile />
        <div id="wrapper" class="hasbg transparent">
          <div id="elementor-header" class="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              class="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div class="elementor-inner">
                <div class="elementor-section-wrap">
                  <Header />
                  <Navbar />
                </div>
              </div>
            </div>
          </div>
          <div id="elementor-sticky-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              className="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Navbar />
                </div>
              </div>
            </div>
          </div>

          <div id="page-content-wrapper" class="">
            <div class="inner">
              <div class="inner-wrapper">
                <div class="sidebar-content fullwidth">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="5459"
                    className="elementor elementor-5459"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <Banner />
                        <HeadTitle />
                        {/* <AccordionCT /> */}
                        <ImageEx />
                        <ContactBus />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <JSberhasil />
    </React.Fragment>
  )
}

export default LayoutExperienceCT
